.circle {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background: url('./propeller-01.svg');
  border-radius: 200px;
  pointer-events: none;
  color: #fff;
  text-align: center;
  font-size: 16px;
}

@keyframes turnPropeller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.cursorText {
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-image: url('./propeller-02.svg');
  animation: turnPropeller 0.25s linear infinite;
}

.project {
  display: inline-block;
  position: relative;
  z-index: 1;
  height: 652px;
  width: 601px;
  background: #1e91d6;
  border-radius: 24px;
  cursor: none;
  background-image: url('https://cdn.discordapp.com/attachments/577203676108685331/790326977714192465/Tile_Image.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projectName {
  background: #fff;
  border-radius: 34px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 12px 20px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
}

.contactWrapper {
  padding: 120px;
}

/* .contact {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  text-decoration: underline;
  transition: 150ms ease;
}

.contact:hover {
  color: #1e91d6;
  cursor: pointer;
} */
